/* global app */
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { SBIntegrations } from 'sb-integrations-betfan'

const { useSession, SisStreams } = SBIntegrations

const LiveStream = ({ sportId, isLogged }) => {

  const session = useSession()

  const lang = useMemo(() => `lang_${app.config.lang}_${app.config.mainCountryCode}_1_1`.toLowerCase(), [
    app.config.lang, app.config.mainCountryCode
  ])

  return (
    <>
      {isLogged && (
        <SisStreams session={session} lang={lang} sportId={sportId}/>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  const { Auth: { isLogged } } = state;
  return {
      isLogged
  }
};

export default connect(mapStateToProps)(LiveStream)
