/* global app */
import React, { useMemo } from 'react'
import { SBIntegrations } from 'sb-integrations-betfan'

const { useSession, BetradarLiveChannelOnline } = SBIntegrations

const BetradarLCO = ({ streamId }) => {
  const session = useSession()

  const lang = useMemo(() => `lang_${app.config.lang}_${app.config.mainCountryCode}_1_1`.toLowerCase(), [
    app.config.lang, app.config.mainCountryCode
  ])

  return (
    <BetradarLiveChannelOnline
      session={session}
      lang={lang}
      isMobile={false}
      allowFullScreen={app.config.betRadarLiveChanneLCOallowFullScreen}
      streamId={streamId}
      streamType={app.config.betRadarLiveChanneLCOstreamType}
      device='Desktop'
      scriptURL={app.config.betRadarLiveChanneLCOlUrl}
      stylesheetURL={app.config.betRadarLiveChannelLCOStyleSheetUrl}
      restrictions={app.config.betRadarLiveChanneLCOrestrictions}
    />
  )
}

export default BetradarLCO
